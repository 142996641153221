﻿.rdt_TableHeader,
.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination,
.rdt_TableCol,
.rdt_TableCol_Sortable {
    @include themify($themes) {
        background-color: themed('colorBackground');
        color: themed('colorText');
    }
}

.rdt_Pagination {
    #pagination-first-page,
    #pagination-previous-page,
    #pagination-next-page,
    #pagination-last-page {
        @include themify($themes) {
            fill: themed('colorText');
        }

        &:disabled {
            fill: $color-light-gray;

        }
    }
    select {
        @include themify($themes) {
            background-color: themed('colorBackground');
        }
    }
}

.rdt_Table {
    & > div {
        @include themify($themes) {
            background-color: themed('colorBackground');
            color: themed('colorText');
        }
    }
    .badge {
        color: #ffffff;
        padding: 6px 20px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: 400;
        
        & span {
            font-size: 10px;
        }

        &.badge-success {
            background-color: $color-accent;
        }

        &.badge-warning {
            background-color: $color-yellow;
        }

        &.badge-primary {
            background-color: $color-blue;
        }
        
        &.badge-secondary {
            background-color: #e7e2e2;
            color: $color-black;
        }

        &.badge-danger {
            background-color: $color-red;
        }

        &.badge-disabled {
            background-color: #dddddd;
        }
    }

}

.rdt_TableHeadRow {
    border: none !important;
    min-height: 15px !important;
}

.react-datatable__filters {
    .rdt_TableBody .rdt_TableRow:first-child div:first-child .custom-checkbox {
        display: none;
    }
}