﻿.list {
    width: 100%;
    height: auto;
    min-height: 45px;
    max-height: 250px;
    overflow-y: auto;
    padding-left: 0;
    @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
        color: themed('colorText');
    }
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px;
        &:not(:last-child) {
            @include themify($themes) {
                border-bottom: 1px solid themed('colorFieldsBorder');
            }
        }
        &-icon {
            cursor: pointer;
        }
        span {
            margin: 0 5px;
        }
    }

    &::-webkit-scrollbar-track {
        border: 1px solid #000;
        padding: 2px 0;
        background-color: #404040;
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: $color-accent;
        border: 1px solid $color-accent;
    }
}