﻿.loading-screen {
    position: fixed;
    top: 70px;
    left: 15px;
    right: 15px;
    bottom: 10px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .7);

    @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
        color: themed('colorText');
        background-color: themed('loadingScreen');
    }

    > img {
        width: 100px;
        height: auto;
    }

    @media screen and (min-width: 576px) {
        top: 60px;
        left: 240px;
        right: 0;
    }
}
