﻿@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .container {
        overflow-y: hidden;
    }
    .modal-dialog {
        display: table;
    }
    .modal-content {
        .modal__body {
            align-self: center !important;
        }
    }
}