.voucher-preview__buttons {
    button {
        background-color: transparent;
        border: none;
    }
}

.voucher-preview__desktop {
    overflow: scroll;
    border: 25px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;

    img {
        width: 1200px;
    }

    @media screen and (min-width: 1200px) {
        width: 1200px;
        height: 650px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &-wrapper {
        width: 1200px;
    }

    button {
        height: 38px;
        border-radius: 2px;
        background: 0 0;
        border: 1px solid #ffffff;
        text-decoration: none;
        vertical-align: middle;
        display: inline-block;
        margin: 0;
        padding: 6px 10px;
        min-width: 112px;
        font-family: "Arial", sans-serif;
        font-size: 10px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        pointer-events: none;

        img {
            width: 10px;
            height: auto;
        }
    }
}

.voucher-preview__app {
    overflow: scroll;
    border: 25px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    img {
        width: 372px;
    }

    @media screen and (min-width: 1200px) {
        width: 420px;
        height: 100%;
        overflow: hidden;
    }

    &-wrapper {
        width: 372px;
    }
}

.my-voucher-app {
    position: relative;
    margin-right: 9px;
    margin-left: 7px;

    &-bg {
        img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
        }
    }

    &-logo {
        .logo {
            z-index: 1;
            position: absolute;
            bottom: 5%;
            left: 25%;

            img {
                max-width: 70px;
                height: auto;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: -1px;
            left: 13px;
            width: 100%;
            height: 101%;
            background-image: url('/img/voucher-preview/mobile-voucher-curve.png');
            background-repeat: no-repeat;
            background-position: right top;
            background-size: 100% 100%;
        }
    }
}

.my-voucher {
    background-color: white !important;
    margin-left: 111px;
    margin-right: 371px;
    padding-left: 37px;
    padding-right: 39px;

    &-bg {
        img {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .my-voucher-logo {
        .logo {
            z-index: 1;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 60%;

            img {
                max-width: 82px;
                height: auto;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('/img/voucher-preview/voucher-curve.png');
            background-repeat: no-repeat;
            background-position: right top;
            background-size: 100% 100%;
        }
    }

    .my-voucher-conditions {
        line-height: 16px;

        p {
            color: inherit !important;
        }
    }
}

.bg-green {
    background-color: #02b245 !important;
}

.bg-grey {
    background-color: #ebebeb !important;
}

.green {
    color: #02b245 !important;
}

.aluminium {
    color: #a9abb0 !important;
}

.bright-grey {
    color: #363C4C !important;
}

.red-alert {
    width: 18px !important;
    height: 18px;
}